<template>
  <div class="usermeage">
    <div>{{ title }}</div>
    <div class="usermbtn">
      <el-button size="small" type="primary" @click="onSubmit('submitForm')"
        >提交</el-button
      >
    </div>
    <div class="usermfrm">
      <el-form
        ref="submitForm"
        :model="submitForm"
        :rules="rules"
        label-width="100px"
        size="small"
      >
        <el-form-item label="姓名：" prop="username">
          <el-input
            v-model="submitForm.username"
            style="width: 30%"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model="submitForm.mobile"
            style="width: 30%"
            placeholder="请输入手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码：" v-if="!userId" prop="password">
          <el-input
            v-model="submitForm.password"
            style="width: 30%"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：" prop="accountStatus">
          <el-radio-group v-model="submitForm.accountStatus">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="邮箱：" prop="email">
          <el-input v-model="submitForm.email" style="width:30%" placeholder="请输入邮箱" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="角色：" prop="appointRoleIdList">
          <el-checkbox-group
            v-model="submitForm.appointRoleIdList"
            @change="onRoleIdChange"
          >
            <el-checkbox
              v-for="item in roleList"
              :key="item.id"
              :label="item.id"
              name="type"
              >{{ item.role_name }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- <el-radio-group v-model="submitForm.appointRoleId">
            <el-radio v-for="item in roleList" :key="item.id" :label="item.id">{{item.role_name}}</el-radio>
          </el-radio-group> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
//createAccount, editAccount
import { infoAccount,createAccount, editAccount } from "@/api/user.js";
import { allRoleList } from "@/api/role.js";
export default {
  name: "usermeage",

  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      title: "账号新增",
      userId: "",
      submitForm: {
        appointRoleId: "",
        accountStatus: 1,
        appointRoleIdList: [],
      },
      pwd: "",
      roleList: [],
      rules: {
        username: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入正确的手机号", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        appointRoleIdList: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个角色",
            trigger: "change",
          },
        ],

        accountStatus: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.getAllRoleList();
    this.userId = this.$route.query.userId;
    if (this.userId) {
      this.title = "账号修改";
      this.submitForm.editManagerId = this.userId;
      this.getinfoAccount();
    }
  },

  methods: {
    async getinfoAccount() {
      const { code, data } = await infoAccount({
        manager_id: this.userId,
      });
      if (code == 200) {
        var appointRoleIdList = []
        data.role_id.split(',').map(item => {
          appointRoleIdList.push(item)
        })
        this.submitForm = {
          editManagerId: data.id,
          username: data.username,
          mobile: data.mobile,
          accountStatus: data.status,
          appointRoleId: data.role_id,
          appointRoleIdList:appointRoleIdList
        };
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const parme = {
            ...this.submitForm,
            password: this.passwordEncryption(this.submitForm.password),
          };
          let res;
          this.userId
            ? (res = await editAccount(parme))
            : (res = await createAccount(parme));
          if (res.code == 200) {
            this.$router.push("/user/list");
            this.$message.success("操作成功");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onRoleIdChange(val) {
      this.submitForm.appointRoleId = val.join(",");
    },
    async getAllRoleList() {
      const { code, data } = await allRoleList();
      if (code == 200) {
        this.roleList = data;
      }
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiHQgcc55lLY/s6cfF+teZ8chBskyTMWwo1ai6nWMxjZh1EijuaenXbJu9DsJy579DGlP2Y8+0cAfCZLYXbp8ab1XANbH4ubqkq5eV9drBbCAIvDZeCS65MaHg3zln/AbTIDxfVEbGcI/g3kRQF7X0Pp7YfABZ6o04SqRfaV8npwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
  },
};
</script>

<style lang="scss" scoped>
.usermeage {
  .usermbtn {
    display: flex;
    justify-content: flex-end;
  }
}
</style>